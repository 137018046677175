<template>
  <div>
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- iframe -->
    <div class="iframe-wrapper">
      <!-- <iframe
        :src="source"
        frameborder="0"
        ref="iframe"
        @load="iframeLoaded"
      ></iframe> -->
    </div>
    <!-- / iframe -->

    <!-- Editor -->
    <div v-if="!isLoading">
      <!-- {{ source }} -->
      <vue-editor v-model="content" />

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
    <!-- / Editor -->
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import LoadingScreen from "../../../../../components/LoadingScreen.vue";
import articles from "../../../../../api/guidance/articles";
export default {
  props: ["tenantId", "article"],
  data() {
    return {
      // isLoading: true,
      isLoading: false,
      content: "",
      isSaving: false,
    };
  },
  components: { VueEditor, LoadingScreen },
  created() {
    this.load();
  },
  computed: {
    source() {
      return this.article["$v"]["media"]["$v"]["source"]["$v"]["sourceUrl"];
    },
  },
  methods: {
    // iframeLoaded() {
    //   let innerHtml = this.$refs.iframe.contentWindow.document.body.innerHtml;
    //   this.content = innerHtml;
    //   this.isLoading = false;
    // },

    async load() {
      let vm = this;
      vm.isLoading = true;

      try {
        this.content = await axios
          .create()
          .get(this.source)
          .then((r) => r.data)
          .then((data) => {
            let inner = /<body.*?>([\s\S]*)<\/body>/.exec(data)[1];
            return `<html><body>` + inner + `</body></html>`
          });
      } catch (e) {
        console.log(e);
      }

      vm.isLoading = false;
    },

    async save() {
      let vm = this;
      vm.isSaving = true;

      let htmlString =
        `<html>
      <head>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
       <style>
            body { font-family: Arial, Helvetica, sans-serif; }
        </style>
      </head>
      <body>` +
        this.content +
        `</body></html>`;

      let blob = new Blob([htmlString], { type: "text/html" });

      try {
        await articles.attachArticleMediaStreamed(
          vm.tenantId,
          vm.article["$v"]["id"],
          blob
        );
        vm.$emit("updated");
        vm.$message.success("Article updated successfully");
      } catch (e) {
        vm.$message.error("Error attaching article");
        console.log(e);
      }

      vm.isSaving = false;
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  padding: 30px;
  text-align: center;
}

.iframe-wrapper {
  display: none;
}

.save-button {
  margin-top: 20px;
}
</style>