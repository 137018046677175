<template>
  <div class="view-admin-guidance-article">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined">
      <h1 class="page-title">Article: {{ article["$v"]["displayName"] }}</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          v-if="canEdit"
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Tabs -->
    <div v-if="!isLoadingCombined">
      <!-- <a-tabs v-model="selectedTab">
        <a-tab-pane tab="Articles" key="articles">
            <article-articles
             :article="article" :tenant-id="tenantId"
             :is-loading="isLoadingContentLinks"
             :content-links="contentLinks"
             @reload="loadContentLinks"
             ></article-articles>
        </a-tab-pane>
      </a-tabs> -->

      <content-link-tabs
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :parent-id="article['$v'].id"
        parent-type="Article"
        :content-links="contentLinks"
        :is-loading="isLoadingContentLinks"
        :types="['topics', 'help-videos', 'downloads', 'links']"
        :children-of="['topics']"
        @refresh="loadContentLinks"
        :show-details-tab="true"
        :can-edit="canEdit"
      >

        <div
          class="article-media-wrapper"
          :class="{ viewing: articleTab == 'view' }"
        >
          <article-media
            v-if="articleTab == 'view'"
            :media="article['$v']['media']"
            :min-height="500"
          ></article-media>
          <article-html-editor
            @updated="loadArticle"
            v-if="articleTab == 'edit'"
            :tenant-id="tenantId"
            :article="article"
          ></article-html-editor>
        </div>
      </content-link-tabs>
    </div>
    <!-- / Tabs -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import articles from "../../../../api/guidance/articles";
// import ArticleArticles from "./Tabs/ArticleArticles.vue";
import contentLinks from "../../../../api/guidance/content-links";
import ContentLinkTabs from "../../../../components/Admin/Guidance/ContentLinkTabs.vue";
import ArticleMedia from "../../../../views/Guidance/Show/TopicTabs/ArticleMedia.vue";
import ArticleHtmlEditor from "./Show/ArticleHtmlEditor.vue";

export default {
  components: {
    LoadingScreen,
    ContentLinkTabs,
    ArticleMedia,
    ArticleHtmlEditor,
  },

  data() {
    return {
      isLoadingArticle: true,
      article: null,

      isDeleting: false,

      isLoadingParentContentLinks: false,
      parentContentLinks: [],

      isLoadingChildrenContentLinks: false,
      childrenContentLinks: [],

      articleTab: "view",
    };
  },

  created() {
    // if (!this.article) {
    //   this.$message.info("Article not found");
    //   this.$router.push("/admin/guidance/articles");
    // }
    this.loadArticle();
    this.loadContentLinks();
  },

  methods: {
    goBack() {
      if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/articles");
      }
    },

    edit() {
      this.$router.push("/admin/guidance/articles/" + this.articleId + "/edit");
    },

    loadArticle() {
      let vm = this;
      vm.isLoadingArticle = true;
      articles
        .getArticle(this.tenantId, this.articleId)
        .then((r) => {
          vm.isLoadingArticle = false;
          vm.article = r.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.info("Article not found");
          this.$router.push("/admin/guidance/articles");
        });
    },

    loadContentLinks() {
      this.loadChildrenContentLinks();
      this.loadParentContentLinks();
    },

    loadChildrenContentLinks() {
      let vm = this;
      vm.isLoadingChildrenContentLinks = true;
      contentLinks
        .getContentLinksForOrgByParentEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.articleId
        )
        .then((r) => {
          vm.isLoadingChildrenContentLinks = false;
          vm.childrenContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChildrenContentLinks = false;
        });
    },

    loadParentContentLinks() {
      let vm = this;
      vm.isLoadingParentContentLinks = true;
      contentLinks
        .getContentLinksForOrgByChildEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.articleId
        )
        .then((r) => {
          vm.isLoadingParentContentLinks = false;
          vm.parentContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingParentContentLinks = false;
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      articles
        .deleteArticle(vm.tenantId, vm.articleId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Article deleted successfully");
          vm.$router.push("/admin/guidance/articles");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting article");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor"
    }),

    isLoadingContentLinks() {
      return (
        this.isLoadingParentContentLinks || this.isLoadingChildrenContentLinks
      );
    },

    contentLinks() {
      return _.unionBy(
        this.parentContentLinks,
        this.childrenContentLinks,
        "$v.id"
      );
    },

    articleId() {
      return this.$route.params.id;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingArticle;
    },

    canEdit() {
      if (!this.article) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(
        this.article["$v"]["ownerId"]
      );
    },
  },
};
</script>

<style lang="scss">
.view-admin-guidance-article {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .article-media-wrapper {
    background: #fff;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;

    &.viewing {
      min-height: 500px;
    }
  }
}
</style>